<template>
  <div class="qrcodes-list-page">
    <div class="qrcodes-list-header">
      <v-add-btn :to="{ name: 'qrcodes.types' }" />
    </div>
    <v-qrcodes-list />
  </div>
</template>

<script>
import VQRCodesList from "../../components/qrcodes/VQRCodesList";

export default {
  name: "QRCodesList",

  layout: "default",

  components: {
    "v-qrcodes-list": VQRCodesList,
  },

  metaInfo() {
    return { title: this.$t("Navigation.QRCodes") };
  },
};
</script>

<style lang="scss" scoped>
.qrcodes-list-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}
</style>
