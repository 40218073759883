<template>
  <div class="qrcodes-list">
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="qrcodes"
      :items-per-page="25"
      :loading="isLoading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, -1] }"
      item-key="id"
    >
      <template v-slot:[`item.preview`]="{ item }">
        <v-qrcode
          :text="qrcodeData(item)"
          @qrcode:click="qrcodeSelectForPreview = item"
          :size="120"
        />
      </template>
      <template v-slot:[`item.createdAt`]="{ value }">
        {{ value | dateHumanizeFormat(locale) }}
      </template>
      <template v-slot:[`item.scanned`]="{ item, value }">
        <span>{{ !isStaticCode(item.codeType) ? value : "—" }}</span>
        <v-tooltip v-if="isStaticCode(item.codeType)" max-width="300" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-2" v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
          </template>
          <span>{{ $t("QRCodes.StaticQRScanHelper") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="mr-2" :to="{ name: 'qrcode.edit', params: { qrcodeId: item.id } }" icon>
          <v-icon> mdi-pencil </v-icon>
        </v-btn>
        <v-btn class="mr-2" :to="{ name: 'qrcode.statistics', params: { qrcodeId: item.id } }" icon>
          <v-icon> mdi-chart-areaspline </v-icon>
        </v-btn>
        <v-btn @click="handleClickDeleteQRCode(item.id)" icon>
          <v-icon> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      :value="!!qrcodeSelectForPreview"
      @click:outside="qrcodeSelectForPreview = null"
      max-width="500"
      overlay-color="#2e2e2e"
      overlay-opacity="0.8"
    >
      <v-card v-if="qrcodeSelectForPreview">
        <v-toolbar class="text-uppercase" color="primary" dark>
          <span>{{ qrcodeSelectForPreview.title }}</span>
          <v-spacer />
          <v-btn @click="qrcodeSelectForPreview = null" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-qrcode :text="qrcodeData(qrcodeSelectForPreview)" is-full />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VQRCode from "./VQRCode";
import qrcodeData from "./qrcodeData";

import qrcodesService from "../../services/qrcodes.service";

import { confirm } from "../../helpers/dialogs";

export default {
  name: "VQRCodesList",

  components: { "v-qrcode": VQRCode },

  mixins: [qrcodeData],

  data() {
    return {
      qrcodes: [],
      isLoading: true,
      qrcodeSelectForPreview: null,
    };
  },

  computed: {
    ...mapGetters({
      locale: "lang/locale",
    }),
    headers() {
      return [
        { text: this.$t("QRCodes.Preview"), value: "preview", sortable: false, width: 120 },
        { text: this.$t("QRCodes.Title"), value: "title" },
        { text: this.$t("QRCodes.Type"), value: "codeType" },
        { text: this.$t("QRCodes.Scanned"), value: "scanned" },
        { text: this.$t("QRCodes.CreatedAt"), value: "createdAt" },
        { text: this.$t("QRCodes.Actions"), value: "actions", sortable: false, width: 160 },
      ];
    },
    isStaticCode() {
      return (codeType) => ["WIFI"].includes(codeType);
    },
  },

  async created() {
    this.isLoading = true;

    const qrcodes = await qrcodesService.getAll();
    this.qrcodes = qrcodes;

    this.isLoading = false;
  },

  methods: {
    async handleClickDeleteQRCode(qrcodeId) {
      const result = await this.$dialog.warning(confirm());

      if (!result) return;

      await qrcodesService.deleteById(qrcodeId);
      this.qrcodes = this.qrcodes.filter((qrcode) => qrcode.id !== qrcodeId);
    },
  },
};
</script>

<style lang="scss" scoped>
.qrcodes-list-header {
  display: grid;
  grid-template-columns: 300px;
  margin-bottom: 20px;
}
</style>