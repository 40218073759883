var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qrcodes-list"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.qrcodes,"items-per-page":25,"loading":_vm.isLoading,"footer-props":{ 'items-per-page-options': [25, 50, 100, -1] },"item-key":"id"},scopedSlots:_vm._u([{key:"item.preview",fn:function(ref){
var item = ref.item;
return [_c('v-qrcode',{attrs:{"text":_vm.qrcodeData(item),"size":120},on:{"qrcode:click":function($event){_vm.qrcodeSelectForPreview = item}}})]}},{key:"item.createdAt",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm._f("dateHumanizeFormat")(value,_vm.locale))+" ")]}},{key:"item.scanned",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(!_vm.isStaticCode(item.codeType) ? value : "—"))]),(_vm.isStaticCode(item.codeType))?_c('v-tooltip',{attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("QRCodes.StaticQRScanHelper")))])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"to":{ name: 'qrcode.edit', params: { qrcodeId: item.id } },"icon":""}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"to":{ name: 'qrcode.statistics', params: { qrcodeId: item.id } },"icon":""}},[_c('v-icon',[_vm._v(" mdi-chart-areaspline ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleClickDeleteQRCode(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"value":!!_vm.qrcodeSelectForPreview,"max-width":"500","overlay-color":"#2e2e2e","overlay-opacity":"0.8"},on:{"click:outside":function($event){_vm.qrcodeSelectForPreview = null}}},[(_vm.qrcodeSelectForPreview)?_c('v-card',[_c('v-toolbar',{staticClass:"text-uppercase",attrs:{"color":"primary","dark":""}},[_c('span',[_vm._v(_vm._s(_vm.qrcodeSelectForPreview.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.qrcodeSelectForPreview = null}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-qrcode',{attrs:{"text":_vm.qrcodeData(_vm.qrcodeSelectForPreview),"is-full":""}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }